@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');


body {
    font-family: 'Inter', sans-serif !important;
  }

a.link:not(.disabled):hover {
  text-decoration: underline !important;
}
